/*
Third Party Scripts
-------------------
*/
    require('intersection-observer');
    import { gsap } from "gsap";
    const imagesLoaded = require('imagesloaded');

/*
Global Variables
-------------------
*/
    const alertBar = document.querySelector(".header-alert");
    const header = document.querySelector("header");
    const main = document.querySelector("main");
    const animateWrappers = document.querySelectorAll(".js-animate-wrapper");
    const mobileOpen = document.querySelector(".mobile-menu_open");
    const mobileClose = document.querySelector(".mobile-nav_close");
    const mobileBack = document.querySelector(".mobile-nav_back-wrapper");
    const mobileTitle = document.querySelector(".mobile-nav_heading h2");
    const mobileNavContainer = document.querySelector(".mobile-nav_container");
    const mobileNavDropDownButtons = mobileNavContainer.querySelectorAll(".nav-drop-down_button");
    const mobileNavDropDownMenus = mobileNavContainer.querySelectorAll(".nav-drop-down");
    const mobileOverlay = document.querySelector(".mobile-nav_overlay");
    let mobileMenuMq = window.matchMedia('(max-width:1050px)');

/*
Header Scripts
-------------------
*/
    // Alert Bar
    function storageAvailable(type) {
        var storage;
        try {
            storage = window[type];
            var x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        }
        catch(e) {
            return e instanceof DOMException && (
                // everything except Firefox
                e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
                // acknowledge QuotaExceededError only if there's something already stored
                (storage && storage.length !== 0);
        }
    }

    if(alertBar) {
        const alertButton = document.querySelector(".header-alert_close");

        const setAlertStorage = function(key, value, ttl) {

            // `item` is an object which contains the original value
            // as well as the time when it's supposed to expire
            const item = {
                value: value,
                expiry: ttl,
            }
            localStorage.setItem(key, JSON.stringify(item))
        }

        const getAlertStorage = function(key) {
            const itemStr = localStorage.getItem(key)

            // if the item doesn't exist, return null
            if (!itemStr) {
                return null;
            } else {
                const item = JSON.parse(itemStr)
                const now = new Date()

                // compare the expiry time of the item with the current time
                if (now.getTime() > item.expiry) {
                    // If the item is expired, delete the item from storage
                    // and return null
                    localStorage.removeItem(key)
                    return null;
                }
                return item.value;
            }

        }

        Date.prototype.addDays = function(days) {
            const date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        }

        if(storageAvailable('localStorage')) {
            const checkAlert = function() {
                if(!getAlertStorage("checkAlert")) {
                    alertBar.classList.add("show");
                } 
            }
            checkAlert();
            alertButton.addEventListener("click", function() {
                alertBar.classList.remove("show");
                const today = new Date();
                const expiryDate = today.addDays(3);
                const ttl = expiryDate.getTime();
                setAlertStorage("checkAlert", "beenHere", ttl);
            });
        }
        
    }

    // Header Scroll
    let prevScroll = window.pageYOffset;

    const scrollHeaderAnimation = function() {
        const currentScroll = window.pageYOffset;
        const headerHeight = header.offsetHeight;
        
        if(currentScroll <= headerHeight) {
            header.classList.remove("hide");
            header.classList.add("show");
        }
        else if (prevScroll < currentScroll) {
            // Hide header on scroll down
            header.classList.add("hide");
            header.classList.remove("show");
        } else {
            // Show header on scroll up
            header.classList.remove("hide");
            header.classList.add("show");
        }

        prevScroll = currentScroll;
    }

    window.addEventListener("scroll", function() {
        scrollHeaderAnimation();
    });

    // Mobile Menu
    window.addEventListener('resize', function() {
        if(!mobileMenuMq.matches && mobileNavContainer.classList.contains("active")) {
            mobileNavContainer.classList.remove("active");
            mobileOverlay.classList.remove("active");
            closeMobileSubMenu();
        }
    });

    mobileOpen.addEventListener("click", function() {
        toggleMobileMain();
    });

    mobileClose.addEventListener("click", function() {
        toggleMobileMain();
        closeMobileSubMenu();
    });

    mobileOverlay.addEventListener("click", function() {
        toggleMobileMain();
        closeMobileSubMenu();
    })

    const toggleMobileMain = function() {
        mobileNavContainer.classList.toggle("active");
        mobileOverlay.classList.toggle("active");
        if (typeof drift === 'undefined' || drift === null) {
            return;
        } else {
            drift.on('ready',function(api) {
                if(mobileNavContainer.classList.contains("active")) {
                    api.widget.hide();
                } else {
                    api.widget.show();
                }
            })
        }
    }

    const openMobileSubMenu = function(e) {
        const trigger = e.target;
        const subMenu = trigger.nextElementSibling;
        subMenu.classList.add("active");
        mobileBack.classList.add("active");
        mobileTitle.classList.add("hide");
    }

    const closeMobileSubMenu = function() {
        mobileBack.classList.remove("active");
        mobileTitle.classList.remove("hide");
        mobileNavDropDownMenus.forEach(menu => {
            menu.classList.remove("active");
        })
    }

    if(mobileNavDropDownButtons) {
        mobileBack.addEventListener("click", function() {
            closeMobileSubMenu();
        })
        mobileNavDropDownButtons.forEach(button => {
            button.addEventListener("click", function(e) {
                openMobileSubMenu(e);
            })
        })
    }

/*
Animation Scripts
-------------------
*/
    const fadeInAnimation = function() {

        // Animation
        const animate = function(wrapper) {
            const items = wrapper.querySelectorAll(".js-animate-item");
            const timeline = gsap.timeline();
            const duration = 0.3;
            const delay = 0.3;
            const stagger = 0.3;
            const distance = 30;

            if(wrapper.classList.contains("js-animate-casestudy")) {
                const text = wrapper.querySelector(".js-animate-text");
                const image = wrapper.querySelector(".js-animate-image");
                const line = wrapper.querySelector(".js-animate-line");

                imagesLoaded(image, function () {
                    timeline
                        .set(text, {opacity: 0, x: -30})
                        .set(image, {opacity: 0, x: 30})
                        .set(line, {opacity: 0, y: 30})
                        .set(wrapper, {opacity: 0})
                        .to(wrapper, {opacity: 1}, 0)
                        .to(text, {
                            opacity: 1, 
                            x: 0,
                            duration: duration,
                            delay: delay
                        }, 0)
                        .to(line, {
                            opacity: 1, 
                            y: 0,
                            duration: duration,
                            delay: delay
                        }, 0)
                        .to(image, {
                            opacity: 1, 
                            x: 0,
                            duration: duration,
                            delay: delay
                        }, 0);
                        });                
            } else if(wrapper.classList.contains("js-animate-x")) {
                timeline
                    .set(items, {opacity: 0, x: distance})
                    .set(wrapper, {opacity: 0})
                    .to(wrapper, {opacity: 1}, 0)
                    .to(items, {
                        opacity: 1, 
                        x: 0,
                        duration: duration,
                        delay: delay,
                        stagger: stagger 
                    }, 0);
            } else {
                timeline
                    .set(items, {opacity: 0, y: distance})
                    .set(wrapper, {opacity: 0})
                    .to(wrapper, {opacity: 1}, 0)
                    .to(items, {
                        opacity: 1, 
                        y: 0,
                        duration: duration,
                        delay: delay,
                        stagger: stagger
                    }, 0);
            }
            
        }

        // Intersection observer
        let observer = new IntersectionObserver(function(entries, self) { 
            entries.forEach(entry => {
                if (!entry.isIntersecting) {
                    return;
                }
                animate(entry.target);
                self.unobserve(entry.target);
            })
        }, { 
            threshold: 0.1 
        });
          
        animateWrappers.forEach(wrapper => {
            observer.observe(wrapper);
        });

    }

    if(animateWrappers) {
        fadeInAnimation();
    }

/*
Accordion Scripts
-------------------
*/
    const accordionToggles = document.querySelectorAll(".js-accordion-toggle");
    const accordionContents = document.querySelectorAll(".js-accordion-content");

    window.addEventListener('DOMContentLoaded', () => {

        const hideContent = function(id) {
            accordionContents.forEach(content => {
                const contentId = content.dataset.item;
                if( id === contentId ) {
                    content.style.height = '0';
                }
            })
            accordionToggles.forEach(toggle => {
                const toggleId = toggle.dataset.item;
                if( id === toggleId ) {
                    toggle.classList.remove("active");
                }
            })
        }
    
        const showContent = function(id) {
            accordionContents.forEach(content => {
                const contentId = content.dataset.item;
                if( id === contentId ) {
                    const wrapper = content.querySelector(".js-content-wrapper");
                    let height = wrapper.offsetHeight;
                    content.style.height = height + 'px';
                    console.log(height)
                }
            })
            accordionToggles.forEach(toggle => {
                const toggleId = toggle.dataset.item;
                if( id === toggleId ) {
                    toggle.classList.add("active");
                }
            })
        }
        
        accordionToggles.forEach(toggle => {

            toggle.addEventListener("click", function() {
                const id = this.dataset.item;
                if( this.classList.contains("active") ) {
                    hideContent(id);
                } else {
                    showContent(id);
                }
            })

        })

    })

/*
Progress ToC Scripts
-------------------
*/
window.addEventListener('DOMContentLoaded', () => {

    const toc = document.querySelector('.table-of-contents');
    const tocLinks = document.querySelectorAll('.table_main a');
	const anchorSections = document.querySelectorAll('.anchor_wrapper');
    const contentSections = document.querySelectorAll('.content_wrapper');

    if(tocLinks.length > 0 && anchorSections.length > 0) {

        // Update toc rows
        let minWidthMq = window.matchMedia('(min-width:1184px)');
        let countSections = contentSections.length;
        const updateTocRows = function() {
            if( minWidthMq.matches ) {
                toc.style.gridRow = `1 / span ${countSections}`;
            }
        }

        // Set first link active as default
        const bodyContent = document.querySelector(".body-content");
        const bodyContentTop = bodyContent.offsetTop;

        tocLinks[0].classList.add('active');

        const updateTocLinks = function() {
            const scrollTop = window.scrollY;

            // Update active link based on scroll
            anchorSections.forEach(section => {
                const sectionTop = section.offsetTop + bodyContentTop - 10;
                const sectionHeight = section.clientHeight;
                const sectionBottom = sectionTop + sectionHeight;
                const sectionId = '#' + section.getAttribute('id');

                if( sectionTop <= scrollTop && sectionBottom >= scrollTop ) {
                    tocLinks.forEach(link => {
                        link.classList.remove('active');
                        if( link.getAttribute('href') == sectionId ) {
                            link.classList.add('active');
                        }
                    })
                }
            })
        }

        updateTocRows();
        updateTocLinks();

        window.addEventListener('resize', () => {
            updateTocRows();
        })

        window.addEventListener('scroll', () => {
            updateTocLinks();
        })
    }
	
});

/*
Swiper
-------------------
*/
import Swiper from './components/swiper';

// Single Swiper
const singleSwipers = document.querySelectorAll(".js-single-swiper");
const singleSwipersNext = document.querySelectorAll(".js-single-swiper-container .swiper-button-next");
const singleSwipersPrev = document.querySelectorAll(".js-single-swiper-container .swiper-button-prev");
const singleSwipersPagination = document.querySelectorAll(".js-single-swiper-container .swiper-pagination");

for( let i = 0; i < singleSwipers.length; i++ ) {
    
    singleSwipers[i].classList.add('js-single-swiper-' + i);

    const nextButton = singleSwipersNext[i];
    const prevButton = singleSwipersPrev[i];
    const pagination = singleSwipersPagination[i];

    const singleSwiper = new Swiper('.js-single-swiper-' + i, {
        direction: 'horizontal', 
        spaceBetween: 0,
        loop: true,
        navigation: {
            nextEl: nextButton,
            prevEl: prevButton,
        },
        pagination: {
            el: pagination,
        },
        autoHeight: true,
        slidesPerView: 1
    });

    singleSwiper.on('slideChange', function () {
        const currentIndex = this.realIndex + 1;
        const currentSlide = this.slides[currentIndex];
        const item = currentSlide.querySelector(".js-item");
        if(item) {
            const color = item.dataset.color;
            const swiperContainer = item.closest(".js-single-swiper-container");
            swiperContainer.dataset.color = color;
        }
      });
  
}

// 3-Card Swiper
const threeCardSwipers = document.querySelectorAll(".js-three-card-swiper");
const threeCardSwipersNext = document.querySelectorAll(".js-three-card-swiper-container .swiper-button-next");
const threeCardSwipersPrev = document.querySelectorAll(".js-three-card-swiper-container .swiper-button-prev");
const threeCardSwipersPagination = document.querySelectorAll(".js-three-card-swiper-container .swiper-pagination");

for( let i = 0; i < threeCardSwipers.length; i++ ) {
    
    threeCardSwipers[i].classList.add('js-three-card-swiper-' + i);

    const nextButton = threeCardSwipersNext[i];
    const prevButton = threeCardSwipersPrev[i];
    const pagination = threeCardSwipersPagination[i];

    const threeCardSwiper = new Swiper('.js-three-card-swiper-' + i, {
        direction: 'horizontal', 
        spaceBetween: 32,
        loop: true,
        navigation: {
            nextEl: nextButton,
            prevEl: prevButton,
        },
        pagination: {
            el: pagination,
        },
        slidesPerView: 1,
        breakpoints: {
            1000: {
                slidesPerView: 3,
            }
        }
    });
  
}

/*
Hover Interactions
-------------------
*/

const hoverWrappers = document.querySelectorAll('.js-hover-wrapper');

hoverWrappers.forEach(hoverWrapper => {
    const hoverItem = hoverWrapper.querySelector('.js-hover-item');
    hoverWrapper.addEventListener("mouseenter", function() {
        hoverItem.classList.add("mouse-enter");
    });
    hoverWrapper.addEventListener("mouseleave", function() {
        hoverItem.classList.remove("mouse-enter");
    });
})

/*
Dialog
-------------------
*/

const dialogOpenButtons = document.querySelectorAll(".js-dialog-open");
const dialogCloseButtons = document.querySelectorAll(".js-dialog-close");

dialogOpenButtons.forEach(button => {
    const dialogId = button.dataset.dialog;
    const dialogSelector = '#' + dialogId;
    button.addEventListener("click", function() {
        const dialog = document.querySelector(dialogSelector);
        dialog.classList.add('open');
        button.setAttribute("aria-expanded", true);
    })
})

dialogCloseButtons.forEach(button => {
    const dialogId = button.dataset.dialog;
    const dialogSelector = '#' + dialogId;
    button.addEventListener("click", function() {
        const dialog = document.querySelector(dialogSelector);
        dialog.classList.remove('open');
        dialogOpenButtons.forEach(openButton => {
            const openButtonId = openButton.dataset.dialog;
            if( dialogId == openButtonId ) {
                openButton.setAttribute("aria-expanded", false);
            }
        })
    })
})